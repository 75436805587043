<template>
  <section class="mt-4">
    <v-row class="white elevation-1">
      <v-col class="pa-0">
        <v-data-table
          :items="items"
          :headers="headers"
          class="elevation-1"
          :items-per-page="100"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]={item}>
            <v-btn
              color="primary"
              dark
              icon
              :loading="item.isLoadingDownload"
              @click="onButtonDownload(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </section>
</template>
<script>

export default {
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    documentType: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    items() {
      return this.documents.filter((doc) => doc.documentType === this.documentType);
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$_strings.contract.header.DOCUMENT_NAME,
          class: 'white--text primary',
          value: 'documentName',
          sortable: false,
        },
        {
          text: this.$_strings.contract.header.STATUS,
          class: 'white--text primary',
          value: 'documentStatus',
          sortable: false,
        },
        {
          text: this.$_strings.contract.header.ACTION,
          class: 'white--text primary',
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    onButtonDownload(item) {
      this.$set(item, 'isLoadingDownload', true);
      try {
        if (item.url) {
          this.$_services.uploads.downloadFile(item.url);
        }
      } finally {
        this.$set(item, 'isLoadingDownload', false);
      }
    },
  },
};
</script>
