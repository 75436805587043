<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.label.CONTRACT_FORMAT}} <span class="red--text">*</span></span>
      </v-col>
      <v-col class="pt-0" cols="12" sm="auto">
        <v-radio-group
          class="ma-0"
          v-model="form.contractFormatType"
          disabled
        >
          <v-radio
            :value="'FASTLOG'"
            label="Kontrak Format Fastlog"
          ></v-radio>
          <v-radio
            :value="'COMPANY'"
            label="Format 3PL"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.header.COMPANY_NAME}} <span class="red--text">*</span></span>
      </v-col>
      <v-col md="9">
        <v-tooltip v-model="showTooltipCompanyName" left>
          <template v-slot:activator="{ }">
            <v-autocomplete
              dense
              outlined
              item-text="companyName"
              item-value="companyId"
              placeholder="Nama Perusahaan"
              :items="itemsCompanyList"
              v-model="form.companyId"
              disabled
              @blur="showTooltipCompanyName = false"
            >
            </v-autocomplete>
          </template>
          <p>Jenis Mitra Belum Dipilih</p>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.label.MOU_NUMBER }}<span class="red--text">*</span></span>
      </v-col>
      <v-col md="9">
        <v-text-field
          :value="form.mouNo"
          dense
          outlined
          readonly
          disabled
          placeholder="Nomor MoU"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.header.CONTRACT_NUMBER}} <span class="red--text">*</span></span>
      </v-col>
      <v-col md="9">
        <v-text-field
          height="30"
          outlined
          dense
          placeholder="Nomor Kontrak"
          v-model="form.contractNo"
          readonly
          disabled
          aria-hidden="true"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <span class="subtitle-1">{{ $_strings.contract.label.EFFECTIVE_PERIOD}} <span class="red--text">*</span></span>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          height="30"
          outlined
          dense
          placeholder="Tanggal"
          :value="form.periodStart ? dateFormat(form.periodStart) : null"
          readonly
          disabled
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto">
        <span class="subtitle-1">{{ $_strings.contract.label.UNTIL}} <span class="red--text">*</span></span>
      </v-col>
      <v-col md="3">
        <v-text-field
          height="30"
          outlined
          dense
          placeholder="Tanggal"
          :value="form.periodEnd ? dateFormat(form.periodEnd) : null"
          readonly
          disabled
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { dateFormat } from '../../helper/commonHelpers';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    itemsCompanyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showTooltipCompanyName: false,
    };
  },
  methods: {
    dateFormat,
  },
};
</script>
